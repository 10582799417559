@import '../../../styles/colors';
@import '../../../styles/dims';
@import '../../../styles/breakpoints';

div.Topbar {
    position: fixed;
    display: flex;
    justify-content: space-between;
    top: 0;
    z-index: 99;

    width: 100%;
    height: $topbar-height;
    padding: 0.8rem;

    background: linear-gradient(90deg, $topbar-grad-start, $topbar-grad-end);
    
    @include media-max("tablet-down") { z-index: 112; }

    div.left {
        display: flex;
        align-items: center;
        margin-left: 0.8rem;
        height: 100%;

        img {
            height: 100%;

            filter: $svg-white;
        }

        button.open-menu {
            height: 100%;
            padding: 0;
            margin-left: 0.8rem;

            color: $text-white;

            background: none;
            border: none;

            .material-icons {
                transform: rotate(0);

                transition: transform .1s;
            }

            &.open .material-icons { transform: rotate(-180deg); }
        }
    }

    div.right {
        display: flex;
        align-items: center;
        margin-right: 0.8rem;

        font-family: 'Noto Sans';
        font-size: 1.2rem;
        color: $text-white;
    }
}