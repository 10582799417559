// Global
$global-background: #E8ECEE;
$text-white: #FBF4F5;
$text-black: #0A0C0E;
$text-h2: #273237;
$text-h3: #FBF4F5;
$background-h3: #66A1B7;
$shadow-h3: #4F90A8;
$svg-white: invert(99%) sepia(16%) saturate(580%) hue-rotate(285deg) brightness(100%) contrast(97%);
$svg-black: invert(3%) sepia(4%) saturate(3452%) hue-rotate(169deg) brightness(93%) contrast(96%);

// Login
$login-grad-start: #E25C63;
$login-grad-end: #D1AF98;
$login-form-background: #FBF4F5;
$login-form-shadow: #C46875;
$login-border: #D1AF98;
$login-error-color: #D72630;
$login-error-background: #EC9397;
$login-button-shadow: #D5939D;

// Topbar
$topbar-grad-start: #213D47;
$topbar-grad-end: #2A4C59;
$topbar-shadow: #192E35;
// Notifications
$notification-count-background: #D72630;
$notification-block-background: #FFFFFF;
$notification-block-shadow: #455761;
$notification-toggle-color: #B9C6CD;
$notification-toggle-active-color: #455761;
$notification-toggle-hover-color: #728D9B;
$notification-review-grad-start: #4F90A8;
$notification-review-grad-end: #7FB1C3;
$notification-review-shadow: #66A1B7;
$notification-review-title: #325C6B;
$notification-review-text: #213D47;
$notification-review-delete-button: #325C6B;
$notification-review-delete-button-hover: #2A4C59;
$notification-booking-grad-start: #6A994E;
$notification-booking-grad-end: #8DB774;
$notification-booking-shadow: #7AAC5D;
$notification-booking-title: #425F31;
$notification-booking-text: #27391D;
$notification-booking-delete-button: #425F31;
$notification-booking-delete-button-hover: #344C27;

// Sidebar
$sidebar-grad-start: #3B6B7C;
$sidebar-grad-end: #4F90A8;
$sidebar-shadow: #192E35;
$sidebar-section: #B2D0DB;
$logout-button-shadow: #192E35;
// Menu Item
$menu-item-text: #FBF4F5;
$menu-item-hover: #325C6B;
$menu-item-active: #66A1B7;
$menu-item-shadow: #2A4C59;
$menu-item-tongue: #E8ECEE;

// Content block
$content-block-background: #FFFFFF;
$content-block-shadow: #D0D9DE;
$button-background: #3B6B7C;
$button-background-hover: #325C6B;
$button-shadow: #2A4C59;

// Table
$table-tool-select-selected: #66A1B7;
$table-tool-select-hover: #325C6B;
$table-tool-select-shadow: #455761;
$table-tool-page-color: #728D9B;
$table-tool-page-hover-color: #455761;
$table-tool-page-disabled-color: #B9C6CD;
$table-tool-search: #728D9B;
$table-tool-search-placeholder: #B9C6CD;
$table-header-border: #E8ECEE;
$table-header-color: #4F646F;
$table-sort-color: #728D9B;
$table-sort-inactive-color: #B9C6CD;
$table-data-border: #E8ECEE;
$table-data-color: #455761;
$table-data-color-main: #0A0C0E;
$table-validate-background: #769A7B;
$table-validate-disabled-background: #9BB59E;
$table-validate-shadow: #8DAA91;
$table-cancel-background: #AD4353;
$table-cancel-disabled-background: #D5939D;
$table-cancel-shadow: #C46875;
$table-switch-background: #4F90A8;
$table-switch-disabled-background: #7FB1C3;
$table-switch-shadow: #66A1B7;
$table-edit-background: #B47F59;
$table-edit-shadow: #BE8F6E;
$table-delete-background: #E25C63;
$table-delete-shadow: #E7787D;

// Overlay
$overlay-background: #14191CF0;
$overlay-background-hidden: #14191C00;
$overlay-block-background: #FFFFFF;
$overlay-block-shadow: #0A0C0E;
$overlay-title-color: #273237;
$overlay-close-background: #E25C63;
$overlay-close-shadow: #E7787D;
$overlay-close-text: #87181D;

// Forms
$form-add-image: #3B6B7C;
$form-add-image-hover: #325C6B;
$form-add-image-shadow: #2A4C59;
$form-border: #273237;
$form-radio-selected: #273237;
$form-radio-hover: #728D9B;
$form-placeholder: #B9C6CD;
$form-change-pass-background: #E7787D;
$form-change-pass-button: #E25C63;
$form-change-pass-button-disabled: #EC9397;
$form-change-pass-shadow: #E7787D;
$form-error-color: #D72630;
$form-error-background: #EC9397;
$form-input-button-background: #D0D9DE;
$form-input-button: #273237;
$form-input-button-hover: #455761;
$form-input-button-disabled: #728D9B;
$form-delete-background: #E25C63;
$form-delete-shadow: #E7787D;
$form-save-background: #6A994E;
$form-save-disabled-background: #8DB774;
$form-save-shadow: #7AAC5D;
// Select
$form-select-arrow-hover: #14191C;
$form-select-selected: #66A1B7;
$form-select-hover: #325C6B;
$form-select-shadow: #455761;
// DatePicker
$date-picker-background: #FFFFFF;
$date-picker-arrow: #273237;
$date-picker-arrow-hover: #14191C;
$date-picker-day-color: #728D9B;
$date-picker-date: #0A0C0E;
$date-picker-date-selected: #FBF4F5;
$date-picker-outside-date: #4F646F;
$date-picker-disabled-date: #8AA0AC;
$date-picker-selected: #66A1B7;
$date-picker-selected-range: #99C0CF;
$date-picker-hover: #325C6B;
// EditorField
$editor-tools-background: #D0D9DE;
$editor-tools-color: #728D9B;
$editor-tools-active-color: #273237;
$editor-tools-hover-color: #455761;
$editor-placeholder-color: #B9C6CD;

// Confirmation
$confirmation-confirm-background: #E25C63;
$confirmation-confirm-shadow: #E7787D;
$confirmation-confirm-positive-background: #6A994E;
$confirmation-confirm-positive-shadow: #7AAC5D;
$confirmation-cancel-background: #4F646F;
$confirmation-cancel-shadow: #5F7886;

// EventBlock
$airbnb-grad-start: #E25C63;
$airbnb-grad-end: #EC9397;
$airbnb-shadow: #E7787D;
$airbnb-title: #E25C63;
$airbnb-text: #87181D;
$abritel-grad-start: #4F90A8;
$abritel-grad-end: #7FB1C3;
$abritel-shadow: #66A1B7;
$abritel-title: #4F90A8;
$arbitel-text: #213D47;
$booking-grad-start: #6A994E;
$booking-grad-end: #8DB774;
$booking-shadow: #7AAC5D;
$booking-title: #6A994E;
$booking-text: #27391D;
$locked-grad-start: #B47F59;
$locked-grad-end: #C79F83;
$locked-shadow: #BE8F6E;
$locked-title: #B47F59;
$locked-text: #462F1F;

// Calendar
$cal-button-background: #3B6B7C;
$cal-button-background-hover: #325C6B;
$cal-button-background-disabled: #66A1B7;
$cal-button-shadow: #2A4C59;
$week-number-background: #D0D9DE;
$week-number-text: #728D9B;
$day-past-background: #E8ECEE;
$day-past-text: #8AA0AC;
$day-today-background: #C6DBBA;
$day-today-text: #6A994E;
$day-future-background: #FFFFFF;
$day-future-text: #0A0C0E;
$background-event: #728D9B;
$cal-select-background: #DABFAC;

// NotFound
$not-found-text-color: #3B6B7C;
$not-found-filter: invert(40%) sepia(10%) saturate(1811%) hue-rotate(150deg) brightness(92%) contrast(92%);

// Toastify
$toast-color-success: #6A994E;
$toast-color-warning: #B47F59;
$toast-color-error: #D72630;