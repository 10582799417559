@import '../../../styles/colors';
@import '../../../styles/dims';
@import '../../../styles/breakpoints';

div.Confirmation {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 111;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 100%;
    height: 100%;

    background: $overlay-background;
    
    &.hidden {
        visibility: hidden;
        
        .confirmation-block {
            opacity: 0;
            transform: translateY(100%);
        }
    }
    
    .confirmation-block {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;
        padding: 1.6rem;
        
        border-radius: 1.6rem;
        background: $overlay-block-background;
        box-shadow: 0 0 1.6rem $overlay-block-shadow;
        
        opacity: 1;
        transform: translateY(0);
        
        transition: opacity .4s ease, transform .4s ease;
        
        h4 {
            width: 100%;
            margin: 0;
            margin-bottom: 0.8rem;
            
            font-family: 'Noto Sans';
            font-size: 1.2rem;
            font-weight: 700;
            color: $overlay-title-color;
        }
        
        .buttons-container {
            display: flex;
            justify-content: center;
            
            .cancel-button, .confirm-button {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0.8rem;
                
                font-family: 'Noto Sans';
                font-weight: 700;
                font-size: 1.1rem;
                line-height: 1.1rem;
                text-transform: uppercase;
                
                border: none;
                border-radius: 0.8rem;
                
                cursor: pointer;
                
                transition: all .4s;
                
                &:hover { box-shadow: none; }
                .material-icons { display: block; }
                span:not(.material-icons) { margin-left: 0.4rem; }
            }
            
            .cancel-button {
                margin-right: 1.6rem;
                
                color: $text-white;
                background: $confirmation-cancel-background;
                box-shadow: 0 0 0.4rem $confirmation-cancel-shadow;
                
                .material-icons { color: $text-white; }
            }
            
            .confirm-button {
                color: $text-white;
                background: $confirmation-confirm-background;
                box-shadow: 0 0 0.4rem $confirmation-confirm-shadow;

                &.positive {
                    background: $confirmation-confirm-positive-background;
                    box-shadow: 0 0 0.4rem $confirmation-confirm-positive-shadow;
                }
                
                .material-icons { color: $text-white; }
            }
        }
    }
}

div.Overlay div.Confirmation { 
    border-radius: 1.6rem; 

    transition: border-radius .4s ease;

    @include media-max("tablet-down") { border-radius: 0; }
}