@import '../../styles/colors';
@import '../../styles/dims';
@import '../../styles/breakpoints';

div.Admin {
    width: 100%;
    min-height: 100vh;

    background-color: $global-background;

    div.page-container {
        position: relative;
        min-height: calc(100vh - #{$topbar-height});
        padding-top: $topbar-height;

        div.content {
            height: 100%;
            margin-left: $sidebar-width;
            padding: 1.6rem;
            padding-top: 3.2rem;

            transition: margin .4s ease, padding .4s ease;

            @include media-max("desktop-up") { margin-left: 0; }
            @include media-max("tablet-up") {
                padding: 0;
                padding-top: 3.2rem;
            }

            h2 {
                margin: 0;
        
                font-family: 'Noto Sans';
                font-size: 1.8rem;
                line-height: 1.8rem;
                font-weight: 700;
                color: $text-h2;

                opacity: 1;
                transition: opacity .4s, margin .4s ease;

                @include media-max("tablet-up") { margin-left: 1.6rem; }

                &.hidden {
                    opacity: 0;
                    transition: none;
                }
            }
        }
    }    
}