@mixin scrollbar($track-color: transparent, $border-radius: 0.4rem, $width: 0.8rem) {
    @media (pointer: fine) {
        &::-webkit-scrollbar { 
            width: $width;
            height: $width;
        }
                        
        &::-webkit-scrollbar-track { background: $track-color; }
        
        &::-webkit-scrollbar-thumb {
            border-radius: $border-radius;
            background: #888; 
        
            &:hover { background: #555; }
        }
        
        & {
            scrollbar-face-color: #888;
            scrollbar-track-color: $track-color;
        }
    }
}
