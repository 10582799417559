@import '../../../../styles/colors';
@import '../../../../styles/dims';
@import '../../../../styles/forms';


div.ChangePassword {
    .change-password-form {
        @extend .default-form;

        .form-group {
            label, div.field > input, div.field .material-icons { color: $text-white }
            div.field { border-bottom: solid 0.2rem $text-white; }
        }

        .buttons-container { 
            justify-content: flex-end;

            button[type=submit] {
                background: $form-change-pass-button;
                
                &:hover:not(:disabled) { box-shadow: 0 0 0.8rem $form-change-pass-shadow; }

                &:disabled { background: $form-change-pass-button-disabled; }
            }
        }
    }
}