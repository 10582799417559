@import '../../../styles/colors';
@import '../../../styles/dims';
@import '../../../styles/breakpoints';

@keyframes appear-event {
    from {
        opacity: 0;
    } to {
        opacity: 1;
    }
}

div.Calendar {
    width: 100%;
    height: 100%;

    .calendar-content {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: -1.6rem;
        margin-right: -1.6rem;
        padding-top: 1.6rem;

        opacity: 1;
        transform: translateY(0);

        transition: opacity .4s ease, transform .4s ease, margin .4s ease;

        @include media-max("tablet-up") { margin: 0; }

        .cal {
            overflow: hidden;
            width: 100%;
            margin-bottom: 1.6rem;
            margin-right: 1.6rem;

            background-color: $content-block-background;

            border-radius: 1.6rem;
            box-shadow: 0 0 0.8rem $content-block-shadow;

            transition: margin .4s ease, border-radius .4s ease;

            @include media-max("tablet-up") {
                margin-right: 0;
                
                border-radius: 0;
            }

            .block-content {
                position: relative;
                display: flex;
                flex-direction: column;

                .fc {
                    .fc-header-toolbar {
                        margin: 0;
                        padding: 1.6rem 0.8rem;

                        background-color: $background-h3;
                        box-shadow: 0 0 0.4rem $shadow-h3;

                        .fc-toolbar-title {
                            margin: 0;

                            font-family: 'Noto Sans';
                            font-size: 1.4rem;
                            line-height: 2.6rem;
                            font-weight: 700;
                            text-transform: capitalize;
                            color: $text-h3;
                        }
    
                        .fc-button {
                            border-radius: 0.8rem
                        }
    
                        .fc-button-primary {
                            padding: 0.4rem 0.8rem;
    
                            font-family: 'Noto Sans';
                            font-size: 1.1rem;
                            font-weight: 700;
                            color: $text-white;
    
                            background: $cal-button-background;
                            border: none;
                            box-shadow: none;
                            
                            transition: all .4s;

                            .fc-icon {
                                font-size: 1.4rem;
                            }
                        }
                        
                        .fc-button-primary:hover {
                            background: $cal-button-background-hover;
                            box-shadow: 0 0 0.4rem $cal-button-shadow;
                        }
    
                        .fc-button-primary:disabled {
                            background: $cal-button-background-disabled;
                            box-shadow: none;
                        }
    
                        .fc-button-group {
                            .fc-button:not(:last-child) {
                                border-top-right-radius: 0;
                                border-bottom-right-radius: 0;
                            }
                            .fc-button:not(:first-child) {
                                border-top-left-radius: 0;
                                border-bottom-left-radius: 0;
                            }
                        }
                    }

                    .fc-view-harness {
                        padding: 1.6rem 0.8rem;

                        .fc-col-header, .fc-daygrid-body, .fc-scrollgrid-sync-table { width: 100% !important; }
    
                        .fc-col-header-cell-cushion {
                            padding: 0.4rem;
    
                            font-family: 'Roboto';
                            font-size: 1.1rem;
                            font-weight: 500;
                            text-transform: capitalize;
                        }
    
                        .fc-daygrid-day-number {
                            font-family: 'Roboto';
                            font-size: 1.1rem;
                            font-weight: 400;
                        }
    
                        .fc-day-past {
                            background: $day-past-background;
    
                            .fc-daygrid-day-number {
                                color: $day-past-text;
                            }
                        }
    
                        .fc-day-today {
                            background: $day-today-background;
    
                            .fc-daygrid-day-number {
                                font-weight: 500;
                                color: $day-today-text;
                            }
                        }
    
                        .fc-day-future {
                            background: $day-future-background;
    
                            .fc-daygrid-day-number {
                                color: $day-future-text;
                            }
                        }
    
                        .fc-day-past, .fc-day-today {
                            .background-event {
                                display: none;
                            }
                        }
    
                        .fc-daygrid-week-number {
                            padding: 0.2rem;
    
                            font-family: 'Roboto';
                            font-size: 1rem;
                            font-weight: 400;
                            color: $week-number-text;
    
                            background: $week-number-background;
                            border-bottom-right-radius: 0.4rem;
                        }
    
                        .fc-daygrid-event {
                            margin: 0.2rem;
                            padding: 0.8rem;
    
                            border-radius: 0.8rem;

                            @for $i from 1 through 10 {
                                &:nth-child(#{$i}) {
                                    animation: #{.4 + ($i - 1) * .2}s ease both appear-event;
                                }
                            }
    
                            .fc-event-title-container {
                                font-family: 'Roboto';
                                font-size: 1.1rem;
                                font-weight: 500;

                                .fc-event-title {
                                    display: flex;
                                    align-items: center;

                                    .material-icons {
                                        display: block;
                                        margin-left: 0.4rem;
                                    }
                                }
                            }
                        }
    
                        .airbnb-event {
                            background: linear-gradient(90deg, $airbnb-grad-start, $airbnb-grad-end);
                            border: none;
                            box-shadow: 0 0 0.4rem $airbnb-shadow;
                            
                            .fc-event-title-container {
                                color: $airbnb-text;
                            }
                        }
    
                        .abritel-event {
                            background: linear-gradient(90deg, $abritel-grad-start, $abritel-grad-end);
                            border: none;
                            box-shadow: 0 0 0.4rem $abritel-shadow;
                            
                            .fc-event-title-container {
                                color: $arbitel-text;
                            }
                        }
    
                        .booking-event {
                            background: linear-gradient(90deg, $booking-grad-start, $booking-grad-end);
                            border: none;
                            box-shadow: 0 0 0.4rem $booking-shadow;

                            &:hover {
                                box-shadow: 0 0 0.8rem $booking-shadow;
                            }
                            
                            .fc-event-title-container {
                                color: $booking-text;
                            }
                        }
    
                        .locked-event {
                            background: linear-gradient(90deg, $locked-grad-start, $locked-grad-end);
                            border: none;
                            box-shadow: 0 0 0.4rem $locked-shadow;

                            &:hover {
                                box-shadow: 0 0 0.8rem $locked-shadow;
                            }
                            
                            .fc-event-title-container {
                                color: $locked-text;
                            }
                        }
    
                        .background-event {
                            background: $background-event;
                        }
    
                        .booking-event, .locked-event {
                            cursor: pointer;

                            transition: all .4s;
                        }
    
                        .fc-highlight {
                            background: $cal-select-background;
                        }
                    }
                }
            }
        }
    }

    &.hidden {
        .calendar-content {
            opacity: 0;
            transform: translateY(8rem);
        }
    }
}