@import '../../../styles/colors';
@import '../../../styles/dims';
@import '../../../styles/content';

div.Reviews {
    width: 100%;
    height: 100%;

    .reviews-content {
        @extend .default-content;

        opacity: 1;
        transform: translateY(0);

        transition: opacity .4s ease, transform .4s ease;

        @for $i from 1 through 10 {
            &:nth-child(#{$i}) {
                transition-delay: #{($i - 1) * .2}s;
            }
        }

        .reviews-list {
            @extend .default-list;
        }
    }

    &.hidden {
        .reviews-content {
            opacity: 0;
            transform: translateY(8rem);
        }
    }
}