@import '../../../../styles/colors';
@import '../../../../styles/dims';

div.EventBlock {
    position: relative;
    padding: 0.8rem;
    margin-bottom: 0.8rem;
    margin-right: 0.8rem;
    overflow: hidden;
    
    border-radius: 0.8rem;
    background-color: $background-h3;

    opacity: 1;
    transform: translateY(0);

    transition: opacity .4s ease, transform .4s ease;

    &.hidden {
        opacity: 0;
        transform: translateY(1.6rem);
    }
    
    h4 {
        margin: 0;
        margin-bottom: 0.8rem;
        
        font-family: 'Roboto';
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.4rem;
        color: $text-white;
        text-align: right;
    }
    
    div.event-info {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: -0.8rem;
        margin-right: -0.8rem;
        
        div.date, div.customer {
            flex: 1 1 0;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-right: 0.8rem;
            margin-bottom: 0.8rem;
            max-width: calc(100% - 0.8rem);
            
            .material-icons { color: $text-white; }
            
            span:not(.material-icons) {
                overflow: hidden;
                margin-left: 0.4rem;
                
                font-family: 'Roboto';
                font-size: 1.1rem;
                line-height: 1.2rem;
                font-weight: 400;
                color: $text-white;
                text-overflow: ellipsis;
                white-space: nowrap;
                
                a {                   
                    z-index: 51; 
                    text-decoration: none;
                    color: inherit;
                    pointer-events: all;
                }

                &:not(.email):first-letter {
                    text-transform: capitalize;
                }
            }
        }
    }

    &.airbnb {
        background: linear-gradient(90deg, $airbnb-grad-start, $airbnb-grad-end);

        box-shadow: 0 0 0.8rem $airbnb-shadow;

        h4 { color: $airbnb-title; }

        div.event-info {
            div.date, div.customer {
                .material-icons, span { color: $airbnb-text; }
            }
        }
    }

    &.abritel, &.without-comment {
        background: linear-gradient(90deg, $abritel-grad-start, $abritel-grad-end);

        box-shadow: 0 0 0.8rem $abritel-shadow;

        h4 { color: $abritel-title; }

        div.event-info {
            div.date, div.customer {
                .material-icons, span { color: $arbitel-text; }
            }
        }
    }

    &.without-comment {
        &:hover { box-shadow: 0 0 1.6rem $abritel-shadow }
    }

    &.booking, &.not-validated {
        background: linear-gradient(90deg, $booking-grad-start, $booking-grad-end);

        box-shadow: 0 0 0.8rem $booking-shadow;

        &:hover { box-shadow: 0 0 1.6rem $booking-shadow; }

        h4 { color: $booking-title; }

        div.event-info {
            div.date, div.customer {
                .material-icons, span { color: $booking-text; }
            }
        }
    }

    &.locked {
        background: linear-gradient(90deg, $locked-grad-start, $locked-grad-end);

        box-shadow: 0 0 0.8rem $locked-shadow;

        &:hover { box-shadow: 0 0 1.6rem $locked-shadow; }

        h4 { color: $locked-title; }

        div.event-info {
            div.date, div.customer {
                .material-icons, span { color: $locked-text; }
            }
        }
    }

    &.airbnb, &.abritel, &.locked {
        div.event-info {
            div.date { 
                flex: 1 1 0;
                max-width: calc(100% - 0.8rem);
            }
        }
    }

    &.booking, &.locked, &.not-validated, &.without-comment {
        cursor: pointer;
        
        transition: box-shadow .4s, opacity .4s ease, transform .4s ease;
    }

    .stars-container {
        display: flex;
    }
}

.packing-content {
    @for $i from 1 through 10 {
        &:nth-of-type(#{$i}) {
            @for $j from 1 through 10 {
                .block:nth-of-type(#{$j}) {
                    div.EventBlock {
                        @for $k from 1 through 10 {
                            &:nth-child(#{$k}) {
                                transition: box-shadow .4s linear 0s, opacity #{(.4 + ($k - 1) * .2)}s ease #{($i - 1) * .4 + ($j - 1) * .2}s, transform #{(.4 + ($k - 1) * .2)}s ease #{($i - 1) * .4 + ($j - 1) * .2}s;
                            }
                        }
                    }
                }
            }
        }
    }
}