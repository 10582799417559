@import '../../../../styles/colors';
@import '../../../../styles/dims';
@import '../../../../styles/forms';

div.EditInfo {
    width: 100%;
    
    .edit-form {
        @extend .default-form;
    }
}