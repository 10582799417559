@import '../../../../styles/colors';
@import '../../../../styles/dims';
@import '../../../../styles/forms';

div.CreateSection {
    width: 100%;
    
    .create-form {
        @extend .default-form;
    }
}