@import '../../../../styles/colors';
@import '../../../../styles/dims';
@import '../../../../styles/breakpoints';

li.MenuItem {
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    margin-bottom: 0.4rem;

    background-color: transparent;
    border-radius: 1.6rem 0 0 1.6rem;

    transition: box-shadow .4s, background-color .4s;

    @include media-max("desktop-up") { 
        margin-right: 0.8rem;
        border-radius: 0 1.6rem 1.6rem 0;

        transition: margin 0s linear .4s, border-radius 0s linear .4s;
    }
    
    a {
        display: block;
        padding: 0.8rem;
        padding-left: 1.2rem;
        
        font-family: 'Noto Sans';
        font-size: 1.2rem;
        line-height: 1.2rem;
        font-weight: 400;
        color: $menu-item-text;
        text-decoration: none;
        
        box-shadow: none;

        transition: all .4s;

        div {
            display: flex;
            justify-content: flex-start;
            align-items: center;
    
            .material-icons {
                display: block;
                color: $menu-item-text;
            }
    
            span:not(.material-icons) {
                margin-left: 0.8rem;

                transition: margin .4s;
            }
        }
    }

    &::after {
        display: block;
        position: absolute;
        right: -1.6rem;
        top: 0;
        content: '';
        height: 100%;
        width: 1.6rem;

        background-color: $menu-item-tongue;
        border-radius: 0.8rem;

        transition: all .4s;

        @include media-max("desktop-up") { 
            right: unset;
            left: -1.6rem;
            border-radius: 0;
        }
    }
    
    &:hover {
        box-shadow: 0 0 0.4rem $menu-item-shadow;
        background-color: $menu-item-hover;

        span:not(.material-icons) {
            margin-left: 1.2rem;
        }
    }

    &.active {
        background-color: $menu-item-active;
    }

    &:hover::after, &.active::after {
        width: 2rem;
    }
}