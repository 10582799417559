@import '../../../styles/colors';
@import '../../../styles/dims';
@import '../../../styles/breakpoints';

div.Dashboard {
    width: 100%;
    height: 100%;

    .dashboard-content {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: -1.6rem;
        margin-right: -1.6rem;
        padding-top: 1.6rem;

        transition: margin .4s ease;

        @include media-max("tablet-up") {
            flex-direction: column;
            margin: 0;
        }

        .packing-content {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            width: 50%;

            transition: width 0s ease;

            @include media-max("tablet-up") {
                width: 100%;

                transition: width .4s ease;
            }

            @for $i from 1 through 10 {
                &:nth-of-type(#{$i}) {
                    @for $j from 1 through 10 {
                        .block:nth-of-type(#{$j}) {
                            transition: opacity .4s ease #{($i - 1) * .4 + ($j - 1) * .2}s, 
                                transform .4s ease #{($i - 1) * .4 + ($j - 1) * .2}s,
                                margin .4s ease 0s, width .4s ease 0s, border-radius .4s ease 0s;
                        }
                    }
                }
            }

            .block {
                overflow: hidden;
                width: calc(100% - 1.6rem);
                margin-bottom: 1.6rem;
                margin-right: 1.6rem;
    
                background-color: $content-block-background;
    
                border-radius: 1.6rem;
                box-shadow: 0 0 0.8rem $content-block-shadow;
    
                opacity: 1;
                transform: translateY(0);

                @include media-max("tablet-up") {
                    width: 100%;
                    margin-right: 0;

                    border-radius: 0;
                }

                .block-heading {
                    padding: 1.6rem 0.8rem;
    
                    background-color: $background-h3;
                    box-shadow: 0 0 0.4rem $shadow-h3;
    
                    h3 {
                        display: flex;
                        align-items: center;
                        margin: 0;
        
                        font-family: 'Noto Sans';
                        font-size: 1.4rem;
                        line-height: 2.6rem;
                        font-weight: 700;
                        color: $text-h3;
    
                        .material-icons {
                            display: block;
                            color: $text-h3;
                        }
            
                        span:not(.material-icons) { margin-left: 0.4rem; }
                    }
                }
    
                .block-content {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    margin-bottom: -0.8rem;
                    margin-right: -0.8rem;
                    padding: 0.8rem;
    
                    p {
                        width: calc(100% - 3.2rem);
                        margin: 1.6rem;
    
                        font-family: 'Roboto';
                        font-size: 1.1rem;
                        line-height: 1.1rem;
                        text-align: center;
                    }
                }
            }
        }
    }

    &.hidden {
        .dashboard-content {
            .block {
                opacity: 0;
                transform: translateY(8rem);
            }
        }
    }
}