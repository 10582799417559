@import '../../../styles/colors';
@import '../../../styles/dims';
@import '../../../styles/content';

div.Periods {
    width: 100%;
    height: 100%;

    .periods-content {
        @extend .default-content;

        opacity: 1;
        transform: translateY(0);

        transition: opacity .4s ease, transform .4s ease;

        .periods-list {
            @extend .default-list;
        }
    }

    &.hidden {
        .periods-content {
            opacity: 0;
            transform: translateY(8rem);
        }
    }
}