@import '../../../../styles/colors';
@import '../../../../styles/dims';
@import '../../../../styles/scrollbar';
@import '../../../../styles/breakpoints';

div.Notifications {
    position: relative;
    margin-left: 0.4rem;

    div.notifications-label {
        position: relative;

        cursor: pointer;

        div.notifications-count {
            position: absolute;
            top: -0.4rem;
            right: -0.4rem;
            width: 1.4rem;
            height: 1.4rem;
            padding: 0.2rem;

            font-family: 'Roboto';
            text-align: center;
            font-size: 1rem;
            line-height: 1rem;
            color: $text-white;
            background: $notification-count-background;

            border-radius: 50%;
        }
    }

    div.notifications-container {
        position: fixed;
        top: $topbar-height;
        right: 0;
        height: calc(100% - #{$topbar-height});
        padding: 0.8rem 2.4rem;
        overflow: hidden;

        transition: padding .4s ease;

        @include media-max("phone") { padding: 0rem; }

        div.notifications-block {
            overflow: hidden;
            width: 24rem;
            
            background: $notification-block-background;
            border-radius: 0.8rem;
            box-shadow: 0 0 0.8rem $notification-block-shadow;

            opacity: 1;
            transform: translateX(0);

            transition: opacity .4s ease, transform .4s ease, width .4s ease, border-radius .4s ease;

            @include media-max("phone") { 
                width: 100vw;
                height: 100%;
                
                border-radius: 0;
            }

            &.hidden{
                opacity: 0;
                transform: translateX(24rem);

                @include media-max("phone") { transform: translateX(100vw); }
            }
    
            div.notifications-content {
                position: relative;
                display: flex;
                flex-direction: column;
                max-height: 36rem;
        
                color: $text-black;

                transition: height .4s ease;

                @include media-max("phone") { 
                    height: 100%; 
                    max-height: unset;
                }
        
                .notifications-toggle {
                    padding: 0.8rem;
                    padding-bottom: 0.4rem;

                    transition: padding .4s ease;

                    @include media-max("phone") { padding-top: 1.6rem; }
        
                    span {
                        font-family: 'Noto Sans';
                        font-size: 1.2rem;
                        font-weight: 400;
                        color: $notification-toggle-color;
        
                        transition: color .4s;
        
                        &:first-child { margin-right: 0.8rem; }
            
                        &.active {
                            font-weight: 700;
                            color: $notification-toggle-active-color;
                        }
            
                        &:not(.active) {
                            cursor: pointer;
                            
                            &:hover { color: $notification-toggle-hover-color; }
                        }
                    }
                }
        
                .notifications-content {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    overflow-x: hidden;
                    overflow-y: auto;
                    padding: 0.8rem;
    
                    @include scrollbar(none, 0, 0.8rem);
    
                    p {
                        width: calc(100% - 3.2rem);
                        margin: 1.6rem;
    
                        font-family: 'Roboto';
                        font-size: 1.1rem;
                        line-height: 1.1rem;
                        text-align: center;
                    }
    
                    button {
                        margin-top: -0.4rem;
                        padding: 0.4rem;
    
                        font-family: 'Roboto';
                        font-weight: 500;
                        font-size: 1rem;
                        line-height: 1rem;
                        color: $notification-toggle-active-color;
    
                        background: none;
                        border: none;
    
                        cursor: pointer;
    
                        transition: color .4s;
    
                        &:hover { color: $notification-toggle-hover-color }
                    }
                }
            }
        }
    }
}
