@import '../../../../styles/colors';
@import '../../../../styles/dims';
@import '../../../../styles/forms';


div.EditSettings {
    .settings-form {
        @extend .default-form;

        .buttons-container { justify-content: flex-end; }
    }
}