@import '../../../../styles/colors';
@import '../../../../styles/dims';
@import '../../../../styles/forms';

div.EditUser {
    width: 100%;
    
    .edit-form {
        @extend .default-form
    }
}