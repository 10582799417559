@import '../../../../../styles/colors';

@keyframes appear-notif {
    from {
        opacity: 0;
        transform: translateY(2rem);
    } to {
        opacity: 1;
        transform: translateY(0);
    }
}

div.NotificationBlock {
    position: relative;
    margin-bottom: 0.8rem;
    padding: 0.4rem;

    border-radius: 0.4rem;
    cursor: pointer;

    @for $i from 1 through 10 {
        &:nth-child(#{$i}) {
            animation: #{.4 + ($i - 1) * .2}s ease both appear-notif;
        }
    }

    transition: box-shadow .4s;

    button.delete-button {
        position: absolute;
        top: 0.4rem;
        left: 0.4rem;
        margin: 0 !important;
        padding: 0 !important;
        opacity: 0;

        transition: color .4s, opacity .4s;
    }

    h5 {
        margin: 0;
        margin-bottom: 0.4rem;

        font-family: 'Roboto';
        font-size: 1.1rem;
        line-height: 1.1rem;
        font-weight: 500;
        text-align: right;
    }

    .notif-info {
        display: flex;
        flex-direction: column;
        margin-bottom: -0.4rem;

        .info {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            margin-bottom: 0.4rem;
    
            span:not(.material-icons) {
                margin-left: 0.4rem;
                overflow: hidden;
    
                font-family: 'Roboto';
                font-size: 1rem;
                line-height: 1rem;
                font-weight: 400;
                text-overflow: ellipsis;
                white-space: nowrap;
    
                &:first-letter { text-transform: capitalize; }
            }
        }
    
    }

    &.booking {
        background: linear-gradient(90deg, $notification-booking-grad-start, $notification-booking-grad-end);
        box-shadow: 0 0 0.4rem $notification-booking-shadow;

        button.delete-button {
            color: $notification-booking-delete-button !important;

            &:hover { color: $notification-booking-delete-button-hover !important; }
        }
        
        &:hover { box-shadow: 0 0 0.8rem $notification-booking-shadow; }
        h5 { color: $notification-booking-title; }
        .info { color: $notification-booking-text; }
    }

    &.review {
        background: linear-gradient(90deg, $notification-review-grad-start, $notification-review-grad-end);
        box-shadow: 0 0 0.4rem $notification-review-shadow;
        
        button.delete-button {
            color: $notification-review-delete-button !important;

            &:hover { color: $notification-review-delete-button-hover !important; }
        }

        &:hover { box-shadow: 0 0 0.8rem $notification-review-shadow; }
        h5 { color: $notification-review-title; }
        .info { color: $notification-review-text; }
    }

    &:hover {
        button.delete-button { opacity: 1; }
    }
}