@import '../../styles/colors';
@import '../../styles/breakpoints';

div.Login {
    position: fixed;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 100%;
    height: 100%;

    background: linear-gradient(135deg, $login-grad-start, $login-grad-end);

    .login-form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;
        padding: 3.2rem;
    
        background-color: $login-form-background;
        border-radius: 1.6rem;
        box-shadow: 0 0 1.6rem $login-form-shadow;

        opacity: 1;
        transform: scale(1);

        transition: opacity .4s, transform .4s, padding .4s ease;

        @include media-max("tablet-down") { margin: 1.6rem; }
        @include media-max("phone") { padding: 1.6rem; }

        &.hidden {
            opacity: 0;
            transform: scale(0);
        }
        
        img {
            width: calc(100%);
            max-width: 32rem;
            display: block;
            margin: 0 auto;

            filter: $svg-black;

            transition: width .4s ease;
        }

        h2 {
            margin: 1.6rem;
        
            font-family: 'Noto Sans';
            font-size: 1.4rem;
            font-weight: 700;
            text-align: center;
            text-transform: uppercase;
            color: $text-black;
        }

        form {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            align-content: center;
            width: 100%;
        }

        div.form-error {
            width: 80%;
            padding: 0.8rem;
            margin: 0;
            margin-bottom: 1.6rem;
        
            font-family: 'Noto Sans';
            font-weight: 400;
            font-size: 1.2rem;
            color: $login-error-color;
        
            background-color: $login-error-background;
            border-radius: 0.4rem;
        }

        .form-group {
            display: flex;
            flex-direction: column;
            margin-bottom: 1.6rem;
            width: 80%;

            label {
                font-family: 'Noto Sans';
                font-size: 1.2rem;
                font-weight: 400;
                text-align: left;
                color: $text-black;
            }

            div.field {
                display: flex;
                align-content: stretch;
                align-items: center;
                width: 100%;
                margin-top: 0.4rem;
                
                border-bottom: solid 0.2rem $login-border;

                &.error {
                    background-color: $login-error-background;
                    border-color: $login-error-color;
                    
                    .material-icons, input {
                        color: $login-error-color;
                    }
                }

                .material-icons {
                    display: block;
                    color: $login-border;
                    font-size: 1.8rem;
                }
                
                input {
                    flex-grow: 2;
                    width: 100%;
                    margin: 0;
                    margin-left: 0.4rem;
                    padding: 0.4rem;
                
                    font-size: 1.4rem;
                    font-family: 'Roboto';
                    font-weight: 400;
                    color: $text-black;
                
                    background: none;
                    border: none;
                }
            }

            div.error {
                margin-top: 0.4rem;
            
                font-size: 1.2rem;
                font-family: 'Noto Sans';
                font-weight: 700;
                color: $login-error-color;
            }
        }
        
        button {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 1.6rem;
        
            font-family: 'Noto Sans';
            font-weight: 700;
            font-size: 1.4rem;
            line-height: 1.4rem;
            text-transform: uppercase;
            color: $text-white;
        
            background: linear-gradient(135deg, $login-grad-start, $login-grad-end);
            border: none;
            border-radius: 0.8rem;
            box-shadow: 0 0 0.8rem $login-button-shadow;
        
            cursor: pointer;
            transition: all .5s;

            &:hover {
                box-shadow: 0 0 1.6rem $login-button-shadow;
            }
            
            &:disabled {
                box-shadow: none;
                cursor: not-allowed;
            }
            
            .material-icons {
                color: $text-white;
                font-size: 1.8rem;
            }

            span:not(.material-icons) {
                margin-left: 0.4rem;
            }
        }
    }
}