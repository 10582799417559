@import '../../../../styles/colors';
@import '../../../../styles/dims';
@import '../../../../styles/forms';


div.EditUserSettings {
    .user-settings-form {
        @extend .default-form;

        .buttons-container { justify-content: flex-end; }
    }
}