@import '../../../styles/colors';
@import '../../../styles/dims';
@import '../../../styles/content';

div.Slideshow {
    width: 100%;
    height: 100%;

    .slides-content {
        @extend .default-content;

        opacity: 1;
        transform: translateY(0);

        transition: opacity .4s ease, transform .4s ease;

        .slides-list {
            @extend .default-list;
        }
    }

    &.hidden {
        .slides-content {
            opacity: 0;
            transform: translateY(8rem);
        }
    }
}