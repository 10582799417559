@import '../../styles/colors';

div.EditorField {
    flex-grow: 2;
    margin-left: 0.4rem;
    max-width: calc(100% - 0.4rem - 1.8rem);

    &.read-only {
        .EditorField-editor { padding-top: 0; }
    }

    .EditorField-tools {
        padding: 0.4rem;
        margin-bottom: 0.4rem;

        background: $editor-tools-background;
        border-radius: 0.8rem;

        .EditorField-controls {
            display: flex;
            align-items: center;
            height: 2.4rem;
    
            .EditorField-styleButton {
                margin-right: 0.8rem;
                cursor: pointer;
    
                .EditorField-icon {
                    display: flex;
                    align-items: flex-end;
    
                    .material-icons, span {
                        color: $editor-tools-color;
                        transition: color .4s;
                    }
    
                    .material-icons { display: block; }
    
                    span:not(.material-icons) {
                        margin-left: -0.4rem;
                        font-family: 'Roboto';
                        font-size: 1rem;
                    }
                }
    
                &.EditorField-activeButton {
                    .EditorField-icon {
                        .material-icons, span { color: $editor-tools-active-color; }
                    }
                }
    
                &:hover:not(.EditorField-activeButton) {
                    .EditorField-icon {
                        .material-icons, span { color: $editor-tools-hover-color; }
                    }
                }
            }
    
            .EditorField-urlInput {
                display: flex;
                align-items: center;
                margin-right: 0.4rem;
    
                input {
                    margin: 0;
                    margin-right: 0.4rem;
                    padding: 0;
                    width: 16rem;
    
                    font-family: 'Roboto';
                    font-size: 1.1rem;
    
                    background: none;
                    border: none;
                    border-bottom: solid 0.2rem $editor-tools-active-color;
                }
    
                button {
                    padding: 0;
    
                    background: none;
                    border: none;
                    cursor: pointer;
    
                    .material-icons {
                        display: block;
                        color: $editor-tools-active-color;
                    }
                }
            }
        }
    }

    .EditorField-editor {
        flex-grow: 2;
        min-height: 8rem;
        padding: 0.4rem 0;
        
        font-family: 'Roboto' !important;
        font-size: 1.1rem !important;
        line-height: 1.5rem !important;
        color: $text-black !important;

        .public-DraftStyleDefault-ltr { 
            z-index: auto;
            text-align: justify;
            hyphens: auto;
            word-break: break-all;
        }

        .public-DraftEditorPlaceholder-root { 
            z-index: auto;
            color: $editor-placeholder-color !important; 
        }

        ul, ol { margin: 0.8rem 0 !important; }
        h1, h2, h3, h4, h5, h6 { color: $text-black !important; }
        h1, h2, h3 { margin: 0.8rem 0 !important; }
        h4, h5, h6 { margin: 0.4rem 0 !important; }
    }
}