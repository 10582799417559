@import './breakpoints';

.default-content {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: -1.6rem;
    margin-right: -1.6rem;
    padding-top: 1.6rem;

    transition: margin .4s ease;

    @include media-max("tablet-up") { margin: 0; }
}

.default-list {
    overflow: hidden;
    width: 100%;
    margin-bottom: 1.6rem;
    margin-right: 1.6rem;

    background-color: $content-block-background;

    border-radius: 1.6rem;
    box-shadow: 0 0 0.8rem $content-block-shadow;

    transition: margin .4s ease, border-radius .4s ease;

    @include media-max("tablet-up") {
        margin-right: 0;
        
        border-radius: 0;
    }

    .block-heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1.6rem 0.8rem;

        background-color: $background-h3;
        box-shadow: 0 0 0.4rem $shadow-h3;

        h3 {
            display: flex;
            align-items: center;
            margin: 0;

            font-family: 'Noto Sans';
            font-size: 1.4rem;
            line-height: 1.4rem;
            font-weight: 700;
            color: $text-h3;

            .material-icons {
                display: block;
                color: $text-h3;
            }

            span { margin-left: 0.4rem; }
        }

        .create-button {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 0.8rem;
            padding: 0.4rem 0.8rem;               
            
            font-family: 'Noto Sans';
            font-weight: 700;
            font-size: 1.1rem;
            line-height: 1.1rem;
            color: $text-white;
            text-transform: uppercase;
            text-decoration: none;
            
            border: none;
            border-radius: 0.8rem;
            
            color: $text-white;
            background: $button-background;
            box-shadow: none;   

            cursor: pointer;

            transition: all .4s;

            &:hover {
                background: $button-background-hover;
                box-shadow: 0 0 0.4rem $button-shadow;
            }
            
            .material-icons {
                display: block;
                color: $text-white;
            }

            span:not(.material-icons) {
                margin-left: 0.4rem;
            }
        }
    }

    .block-content {
        position: relative;
        display: flex;
        flex-direction: column;

        p {
            width: calc(100% - 3.2rem);
            margin: 1.6rem;

            font-family: 'Roboto';
            font-size: 1.1rem;
            line-height: 1.1rem;
            text-align: center;
        }
    }
}