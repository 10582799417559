@import '../../../styles/colors';
@import '../../../styles/dims';
@import '../../../styles/scrollbar';
@import '../../../styles/breakpoints';

div.Overlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 110;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 6.4rem 0;
    overflow-x: hidden;
    overflow-y: scroll;

    background: $overlay-background;

    transition: padding .4s ease, height .4s ease;

    @include scrollbar(none, 0);
    @include media-max("tablet-down") { 
        top: $topbar-height;
        height: calc(100% - #{$topbar-height});
        padding: 0;
    }

    &.hidden {
        visibility: hidden;
        overflow-y: hidden;

        .overlay-block {
            opacity: 0;
            transform: translateY(100%);
        }
    }

    .overlay-block {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
        width: 76rem;
        padding: 1.6rem;
        
        border-radius: 1.6rem;
        background: $overlay-block-background;
        box-shadow: 0 0 1.6rem $overlay-block-shadow;

        opacity: 1;
        transform: translateY(0);

        transition: opacity .4s ease, transform .4s ease, width .4s ease, border-radius .4s ease, padding .4s ease;

        @include media-between("desktop-up", "desktop-down") { width: 69.6rem; }
        @include media-between("tablet-down", "desktop-up") { width: 63.2rem; }
        @include media-max("tablet-down") {
            width: 100%;

            border-radius: 0;
        }
        @include media-max("phone") { padding: 1.6rem 0.8rem; }

        .close-button {
            position: absolute;
            display: block;
            top: 1.6rem;
            right: 1.6rem;
            margin: 0;
            padding: 0.4rem;

            border: none;
            border-radius: 0.4rem;
            background: $overlay-close-background;
            box-shadow: 0 0 0.4rem $overlay-close-shadow;

            cursor: pointer;

            transition: all .4s;

            &:hover {
                box-shadow: 0 0 0.8rem $overlay-close-shadow;
            }

            .material-icons {
                display: block;
                line-height: 1.8rem;
                color: $overlay-close-text;
            }
        }

        h3 {
            width: calc(100% - 4.2rem);
            margin: 0;
            margin-bottom: 0.8rem;
            margin-right: 4.2rem;

            font-family: 'Noto Sans';
            font-size: 1.4rem;
            font-weight: 700;
            color: $overlay-title-color;

            transition: margin .4s ease, width .4s ease;

            @include media-max("phone") { 
                width: calc(100% - 5rem);
                margin-left: 0.8rem; 
            }
        }
    }
}