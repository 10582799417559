$breakpoints: (
    "phone": 500px,
    "tablet-up": 768px,
    "tablet-down": 900px,
    "desktop-up": 1024px,
    "desktop-down": 1280px,
    "widescreen": 1440px
);

@mixin media-min($_key) {
    @media screen and (min-width: map-get($breakpoints, $_key)) {
        &{ @content; }
    }
}

@mixin media-max($_key) {
    @media screen and (max-width: map-get($breakpoints, $_key) - 1) {
        &{ @content; }
    }
}

@mixin media-between($_keymin, $_keymax) {
    @media screen and (min-width: map-get($breakpoints, $_keymin)) and (max-width: map-get($breakpoints, $_keymax) - 1) {
        &{ @content; }
    }
}