@import '../../../styles/colors';
@import '../../../styles/dims';
@import '../../../styles/scrollbar';
@import '../../../styles/breakpoints';

div.Sidebar {
    position: fixed;
    left: 0;
    z-index: 98;
    overflow: hidden;
    overflow-y: auto;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: left;
    height: calc(100% - #{$topbar-height});
    width: $sidebar-width;

    background: linear-gradient(180deg, $sidebar-grad-start, $sidebar-grad-end);
    // box-shadow: 0 0 0.4rem $sidebar-shadow;

    transition: transform .4s ease, box-shadow .4s ease, width .4s ease;

    @include scrollbar($menu-item-tongue, none, 0);

    @include media-max("desktop-up") { width: $sidebar-width-small; }
    @include media-max("tablet-down") { z-index: 111; }

    &.reduced {
        transform: translateX(-$sidebar-width-small);

        &.open { 
            transform: translateX(0); 
            box-shadow: 0 0 0.8rem $notification-block-shadow;
        }
    }

    div.menu-section {
        width: 100%;
        padding-top: 0.8rem;
        padding-left: 0.8rem;

        @include media-max("desktop-up") { padding-left: 0; }

        h3 {
            margin: 0;
            padding-bottom: 0.4rem;
            margin-bottom: 0.4rem;

            font-family: 'Noto Sans';
            font-size: 1rem;
            line-height: 1rem;
            font-weight: 700;
            text-transform: uppercase;
            color: $sidebar-section;

            border-bottom: solid 0.2rem $sidebar-section;

            @include media-max("desktop-up") { margin-left: 0.8rem; }
        }

        ul {
            margin: 0;
            padding: 0;
        }
    }

    button {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0.8rem;
        margin-left: 1.6rem;
        margin-bottom: 0.8rem;
        padding: 0.8rem;
        width: calc(#{$sidebar-width} - 3.2rem);

        text-align: center;
        font-family: 'Noto Sans';
        font-size: 1.2rem;
        line-height: 1.2rem;
        font-weight: 700;
        text-transform: uppercase;
        color: $text-black;

        border: none;
        border-radius: 0.4rem;
        box-shadow: 0 0 0.4rem $logout-button-shadow;

        cursor: pointer;

        transition: box-shadow .4s;

        @include media-max("desktop-up") {
            width: calc(#{$sidebar-width-small} - 1.6rem);
            margin-left: 0.8rem;
        }

        &:hover {
            box-shadow: 0 0 0.8rem $logout-button-shadow;
        }

        .material-icons {
            display: block;
            color: $text-black;
        }

        span:not(.material-icons) {
            margin-left: 0.4rem;
        }
    }
}