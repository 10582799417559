@import '../../../styles/colors';
@import '../../../styles/dims';
@import '../../../styles/scrollbar';
@import '../../../styles/breakpoints';

@keyframes appear-table {
    from {
        opacity: 0;
        transform: translateY(2rem);
    } to {
        opacity: 1;
        transform: translateY(0);
    }
}

div.Table {
    .table-tools {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0.8rem;

        @include media-max("phone") { justify-content: stretch; }

        .pagination {
            display: flex;
            align-items: center;
            justify-content: center;

            @include media-max("phone") { 
                flex-direction: column;
                align-items: flex-start;
            }

            .pagination-control {
                display: flex;
                align-items: center;
                
                .select-container {
                    width: 6rem;
    
                    .select__control {
                        border: none;
                        border-radius: 0;
                        box-shadow: none;
    
                        cursor: pointer;
    
                        &.select__control--is-focused {
                            outline: none;
                        }
    
                        .select__value-container {
                            font-family: 'Roboto';
                            font-size: 1.1rem;
                            line-height: 1.1rem;
                            font-weight: 400;
                            color: $text-black;
                        }
    
                        .select__indicators {
                            .select__indicator-separator { background: none; }
                            .select__dropdown-indicator {
                                color: $table-tool-page-color;
    
                                transition: color .4s;
    
                            }
                        }
                        
                        &:hover .select__dropdown-indicator, &.select__control--menu-is-open .select__dropdown-indicator { color: $table-tool-page-hover-color; }
    
                    }
    
                    .select__menu {
                        border-radius: 0.8rem;
                        border: none;
        
                        box-shadow: 0 0 0.8rem $table-tool-select-shadow;
        
                        .select__menu-list {
                            display: block;
                            overflow: hidden;
                            border-radius: 0.8rem;
        
                            .select__option {
                                padding: 0.6rem;
    
                                font-family: 'Roboto';
                                font-size: 1.1rem;
                                line-height: 1.1rem;
                                font-weight: 400;
                                color: $text-black;
        
                                &.select__option--is-selected {
                                    background: $table-tool-select-selected;
                                    color: $text-white
                                }
        
                                &:hover {
                                    background: $table-tool-select-hover;
                                    color: $text-white
                                }
                            }
                        }
                    }
                }
    
                span:not(.material-icons) {
                    padding: 0.4rem;
    
                    font-family: 'Roboto';
                    font-size: 1.1rem;
                    line-height: 1.1rem;
                    font-weight: 400;
                    color: $table-data-color;
    
                    strong { 
                        font-weight: 500;
                        color: $table-data-color-main;
                    }
                }
            }

            .navigation-buttons {
                button {
                    padding: 0.6rem;
    
                    border: none;
                    background: none;
    
                    cursor: pointer;
                    
                    .material-icons {
                        display: block;
                        color: $table-tool-page-color;
    
                        transition: color .4s;
    
                        &:hover { color: $table-tool-page-hover-color; }
    
                        &.inactive { color: $table-tool-page-disabled-color; }
                    }
    
                    &:disabled { cursor: default; }
                }
            }

        }

        .global-filter {
            display: flex;
            align-content: stretch;
            align-items: center;
            margin-top: 0.4rem;
            margin-left: 1.6rem;
            width: 18rem;
            
            border-bottom: solid 0.2rem $table-tool-search;

            transition: width .4s ease;

            @include media-between("phone", "tablet-up") { width: 10rem; }
            @include media-max("phone") { flex: 1 1 0; }

            .material-icons {
                display: block;
                color: $table-tool-search;
                font-size: 1.8rem;
            }
            
            input {
                flex-grow: 2;
                width: 100%;
                margin: 0;
                margin-left: 0.4rem;
                padding: 0.4rem;
            
                font-size: 1.1rem;
                font-family: 'Roboto';
                font-weight: 400;
                color: $text-black;
            
                background: none;
                border: none;

                &::placeholder { color: $table-tool-search-placeholder; }
            }
        }
    }

    .table-container {
        overflow-x: auto;
        overflow-y: hidden;

        @include scrollbar(none, 0.8rem, 0.4rem);

        table {
            max-width: 100%;
            width: 100%;
            table-layout: auto;
            border-collapse: collapse;
            border-spacing: 0;
        
            tr {
                th, td {
                    vertical-align: middle;
    
                    &.image {
                        width: 17.6rem;
                    }
                }
    
                th {
                    padding: 0.4rem 0.8rem;
        
                    font-family: 'Noto Sans';
                    font-size: 1.1rem;
                    line-height: 1.3rem;
                    font-weight: 700;
                    color: $table-header-color;
                    text-align: left;
        
                    border-bottom: 2px solid $table-header-border;
    
    
    
                    .header {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
    
                        .header-sort {
                            display: flex;
                            flex-direction: column;
                            margin-top: 0.2rem;
                            margin-left: 0.4rem;
    
                            .material-icons {
                                display: block;
                                color: $table-sort-color;
        
                                &.inactive { color: $table-sort-inactive-color; }
                                &:first-child { margin-bottom: -0.3rem; }
                                &:last-child { margin-top: -0.3rem; }
                            }
                        }
                    }
                }
        
                td {
                    min-width: 8.8rem;
                    padding: 0.8rem;
    
                    font-family: 'Roboto';
                    font-size: 1.1rem;
                    line-height: 1.5rem;
                    font-weight: 400;
                    color: $table-data-color;
                    text-align: left;
    
                    border-bottom: 1px solid $table-data-border;
        
                    img {
                        display: block;
                        width: 16rem;
        
                        border-radius: 0.8rem;
                    }
    
                    a {
                        color: $table-data-color;
                    }
    
                    .number-of {
                        display: flex;
                        justify-content: center;
                        align-items: center;
    
                        .material-icons {
                            display: block;
                            margin: 0 !important;
                        }
    
                        span:not(.material-icons) {
                            margin-left: 0.4rem;
                        }
                    }
    
                    &.main {
                        font-weight: 500;
                        color: $table-data-color-main;
                    }
    
                    &.text {
                        min-width: 16.8rem;
                        color: $table-data-color-main;
    
                        p {
                            width: auto !important;
                            margin: 0 !important;
                            margin-bottom: 0.8rem !important;
                            
                            line-height: 1.5rem !important;
                            text-align: justify !important;
                        }
    
                        ul, ol { margin: 0.8rem 0 !important; }
                        h1, h2, h3, h4, h5, h6 { color: $text-black !important; }
                        h1, h2, h3 { margin: 0.8rem 0 !important; }
                        h4, h5, h6 { margin: 0.4rem 0 !important; }

                        &.main-text { min-width: 32rem; }
                    }
    
                    &.center {
                        text-align: center;
    
                        .material-icons { margin: 0 auto; }
                    }
    
                    &.note {
                        .stars-container {
                            display: flex;
                        }
                    }
    
                    &.section-images {
                        div {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            margin-bottom: -0.4rem;
    
                            img {
                                display: block;
                                margin-bottom: 0.4rem;
                                width: auto;
                                height: 8rem;
                                border-radius: 0.4rem;
                            }
                        }
                    }
    
                    &.no-result { text-align: center; }
    
                    &.actions {
                        .action-buttons {
                            right: 0;
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;
                            margin-right: -0.8rem;
        
                            button, a {
                                display: inline-flex;
                                justify-content: center;
                                align-items: center;
                                margin-right: 0.8rem;
                                padding: 0.6rem;
                            
                                font-family: 'Noto Sans';
                                font-weight: 700;
                                font-size: 1rem;
                                line-height: 1rem;
                                text-transform: uppercase;
                                text-decoration: none;
                                color: $text-white;
                                white-space: nowrap;
                                
                                border: none;
                                border-radius: 0.8rem;
                                box-shadow: none;
                                
                                cursor: pointer;
                                transition: all .4s;
        
                                &.validate-button {
                                    background: $table-validate-background;
        
                                    &:hover { box-shadow: 0 0 0.4rem $table-validate-shadow; }
        
                                    &.disabled {
                                        background-color: $table-validate-disabled-background;
                                        box-shadow: none;
                                        cursor: default;
                                        pointer-events: none;
                                    }
                                }
        
                                &.cancel-button {
                                    background: $table-cancel-background;
        
                                    &:hover { box-shadow: 0 0 0.4rem $table-cancel-shadow; }
        
                                    &.disabled {
                                        background-color: $table-cancel-disabled-background;
                                        box-shadow: none;
                                        cursor: default;
                                        pointer-events: none;
                                    }
                                }
        
                                &.switch-button {
                                    background: $table-switch-background;
        
                                    &:hover { box-shadow: 0 0 0.4rem $table-switch-shadow; }
        
                                    &:disabled {
                                        background-color: $table-switch-disabled-background;
                                        box-shadow: none;
                                        cursor: default;
                                    }
                                }
                                
                                &.edit-button {
                                    background: $table-edit-background;
        
                                    &:hover { box-shadow: 0 0 0.4rem $table-edit-shadow; }
                                }
        
                                &.delete-button {
                                    background: $table-delete-background;
        
                                    &:hover { box-shadow: 0 0 0.4rem $table-delete-shadow; }
                                }
        
                                .material-icons {
                                    display: block;
                                    color: $text-white;
                                }
        
                                span:not(.material-icons) { margin-left: 0.4rem; }
                            }
                        }
                    }
                }
    
                &:last-child td { border-bottom: none; }
            }
        }
    }
}

@for $i from 1 through 10 {
    div[class$="-content"]:nth-child(#{$i}) {
        @for $j from 1 through 50 {
            div.Table table tbody tr:nth-child(#{$j}) {
                animation: #{$i * .2 + ($j - 1) * .2}s ease both appear-table;
            }
        }
    }
}