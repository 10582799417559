@import './breakpoints';

.default-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;

    > img {
        display: block;
        max-height: 256px;
        object-fit: cover;

        border-radius: 0.8rem;
    }

    div.form-error {
        width: 80%;
        padding: 0.8rem;
        margin: 0;
        margin-bottom: 0.8rem;
    
        font-family: 'Noto Sans';
        font-weight: 400;
        font-size: 1.2rem;
        color: $form-error-color;
    
        background-color: $form-error-background;
        border-radius: 0.4rem;
    }

    div.form-row {
        display: flex;
        justify-content: stretch;
        width: 100%;
        padding: 0.8rem;
        margin-right: -0.8rem;

        transition: padding .4 ease;

        @include media-max("tablet-up") { 
            flex-direction: column;
            padding: 0 0.8rem;
        }

        .form-group {
            margin-right: 0.8rem;
            margin-bottom: 0.8rem;
        }
    }

    .image-upload {
        transition: margin .4s ease;

        @include media-max("phone") { margin: 0.4rem 0; }

        label { 
            display: flex; 
            cursor: pointer;
        }

        .label {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0.6rem;
            
            font-family: 'Noto Sans';
            font-size: 1.1rem;
            line-height: 1.1rem;
            font-weight: 700;
            text-align: center;
            color: $text-white;
            text-transform: uppercase;
            
            background: $form-add-image;
            box-shadow: none;
            border-radius: 0.8rem;

            transition: all .4s;

            .material-icons {
                display: block;
                color: $text-white;
            }

            span:not(.material-icons) {
                margin-left: 0.4rem;
            }

            &:hover {
                background: $form-add-image-hover;
                box-shadow: 0 0 0.4rem $form-add-image-shadow;
            }
        }

        img {
            display: block;
            max-height: 256px;
            object-fit: cover;

            border-radius: 0.8rem;
            box-shadow: none;

            transition: all .4s;

            &:hover {
                box-shadow: 0 0 0.4rem $form-add-image-shadow;
            }
        }

        input { display: none; }

        div.error {
            margin-top: 0.4rem;
        
            font-family: 'Noto Sans';
            font-size: 1.2rem;
            font-weight: 700;
            text-align: center;
            color: $form-error-color;
        }
    }

    .stay-horizontal {
        flex: 3 3 0;
        display: flex;
        justify-content: stretch;
    }

    .form-group {
        flex: 3 3 0;
        display: flex;
        flex-direction: column;

        &.small { flex: 1 1 0; }
        &.medium { flex: 2 2 0; }

        label {
            font-family: 'Noto Sans';
            font-size: 1.2rem;
            font-weight: 400;
            text-align: left;
            color: $text-black;
        }

        & > label { white-space: nowrap; }

        div.field {
            display: flex;
            align-content: stretch;
            align-items: center;
            margin-top: 0.4rem;
            
            border-bottom: solid 0.2rem $form-border;

            &.error {
                background-color: $form-error-background;
                border-color: $form-error-color;
                
                > .material-icons, > i, input, .field-addon, .select-container .select__control .select__value-container .select__placeholder { color: $form-error-color; }
            }

            &.editor, &.textarea {
                align-items: flex-start;
            }

            &.radio, &.checkbox { 
                margin-top: 2.3rem; 

                @include media-max("tablet-up") { margin-top: 0.4rem; }
            }

            &.radio {
                border: none;

                label {
                    display: inline-flex;
                    align-items: center;
                    margin-left: 0.8rem;

                    cursor: pointer;

                    .radio-input {
                        display: flex;
                        align-items: center;
    
                        input { 
                            opacity: 0;
                            width: 0;
                            height: 0;

                            &:checked + .radio-control {
                                background: radial-gradient($form-radio-selected 50%, rgba(255, 0, 0, 0) 51%);
                            }

                            &:hover:not(:checked) + .radio-control {
                                background: radial-gradient($form-radio-hover 50%, rgba(255, 0, 0, 0) 51%);
                            }
                        }
    
                        .radio-control {
                            display: block;
                            width: 1.2rem;
                            height: 1.2rem;
    
                            border-radius: 50%;
                            border: solid 0.2rem $form-border;

                            transition: background .4s;
                        }
                    }
    
                    .radio-label {
                        margin-left: 0.4rem;
                        font-size: 1.2rem;
                    }
                }
            }

            &.checkbox {
                border: none;

                label {
                    display: inline-flex;
                    align-items: center;

                    cursor: pointer;

                    .checkbox-input {
                        display: flex;
                        align-items: center;
    
                        input { 
                            opacity: 0;
                            width: 0;
                            height: 0;

                            &:checked + .checkbox-control {
                                background: $form-radio-selected;

                                .material-icons {
                                    color: $text-white;
                                    opacity: 1;
                                }
                            }

                            &:hover:not(:checked) + .checkbox-control {
                                .material-icons { opacity: 1; }
                            }
                        }
    
                        .checkbox-control {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 1.4rem;
                            height: 1.4rem;
                            overflow: hidden;
    
                            background: none;
                            border-radius: 0.4rem;
                            border: solid 0.2rem $form-border;

                            transition: background-color .4s;

                            .material-icons {
                                display: block;

                                color: $form-radio-hover;
                                opacity: 0;

                                transition: color .4s, opacity .2s;
                            }
                        }
                    }
    
                    .checkbox-label {
                        margin-right: 0.4rem;
                        font-size: 1.2rem;
                        text-align: right;
                    }
                }

                &.notif label { margin: 0; }
            }

            &.with-button {
                input { width: 60%; }

                button.recalculate {
                    width: 40%;
                    padding: 0.4rem;
                    border: none;
    
                    font-family: 'Roboto';
                    font-size: 1.1rem;
                    line-height: 1.1rem;
                    color: $form-input-button;

                    background: $form-input-button-background;
                    border-radius: 0.4rem;

                    cursor: pointer;

                    transition: color .4s;

                    &:hover { color: $form-input-button-hover; }
                }
            }

            > .material-icons {
                display: block;
                color: $form-border;
            }

            > i {
                display: block;
                font-size: 24px;
                color: $form-border;
            }
            
            > input {
                width: 100%;
                margin: 0;
                margin-left: 0.4rem;
                padding: 0.4rem;
            
                font-family: 'Roboto';
                font-size: 1.2rem;
                font-weight: 400;
                color: $text-black;
            
                background: none;
                border: none;
            }

            > textarea {
                flex-grow: 2;
                height: 7rem;
                margin: 0;
                margin-left: 0.4rem;
                padding: 0.4rem;

                font-family: 'Roboto';
                font-size: 1.2rem;
                line-height: 1.6rem;
                font-weight: 400;
                color: $text-black;

                background: none;
                border: none;
                resize: none;
            }

            .select-container {
                flex-grow: 2;
                margin-left: 0.4rem;

                .select__control {
                    align-items: stretch;
                    border: none;
                    border-radius: 0;
                    box-shadow: none;

                    background: none;

                    cursor: pointer;

                    &.select__control--is-focused {
                        outline: none;
                    }

                    .select__value-container {
                        padding: 0;

                        font-family: 'Roboto';
                        font-size: 1.1rem;
                        line-height: 1.1rem;
                        font-weight: 400;
                        color: $text-black;

                        .select__placeholder { color: $form-placeholder; }

                        .select__input input { margin-left: 0; }
                    }

                    .select__indicators {
                        .select__indicator-separator { background: none; }
                        .select__clear-indicator, .select__dropdown-indicator {
                            color: $form-border;

                            transition: color .4s;
                        }
                    }
                    
                    &:hover .select__clear-indicator, &:hover .select__dropdown-indicator, &.select__control--menu-is-open .select__dropdown-indicator { color: $form-select-arrow-hover; }

                }

                .select__menu {
                    border-radius: 0.8rem;
                    border: none;
    
                    box-shadow: 0 0 0.8rem $form-select-shadow;
    
                    .select__menu-list {
                        display: block;
                        overflow: auto;
                        border-radius: 0.8rem;
    
                        .select__option {
                            padding: 0.6rem;

                            font-family: 'Roboto';
                            font-size: 1.1rem;
                            line-height: 1.1rem;
                            font-weight: 400;
                            color: $text-black;
    
                            .image-option {
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
            
                                img {
                                    display: block;
                                    width: 6rem;
                                    border-radius: 0.4rem;
                                }
            
                                span {
                                    margin-left: 0.4rem;
            
                                    font-family: 'Roboto';
                                    font-size: 1rem;
                                    font-weight: 400;
                                    color: $text-black;
                                }
                            }
    
                            &.select__option--is-selected {
                                background: $form-select-selected;
                                color: $text-white
                            }
    
                            &:hover {
                                background: $form-select-hover;
                                color: $text-white
                            }
    
                            &.select__option--is-selected, &:hover {
                                .image-option span { color: $text-white; }
                            }
                        }
                    }
                }

                &.select-image, &.select-multi-image {
                    height: 6rem;
                    
                    .select__control {
                        height: 100%;

                        .select__value-container { height: 100%; }
                    }
                }

                &.select-image {
                    .select__single-value {
                        height: 100%;

                        .image-option {
                            height: 100%;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
        
                            img {
                                display: block;
                                height: calc(100% - 0.8rem);
                                margin: 0.4rem 0;

                                border-radius: 0.4rem;
                            }
        
                            span {
                                margin-left: 0.4rem;
        
                                font-family: 'Roboto';
                                font-size: 1rem;
                                font-weight: 400;
                                color: $text-black;
                            }
                        }
                    }
                }

                &.select-multi-image {
                    .select__multi-value {
                        height: 100%;

                        background: none;

                        .image-option {
                            height: 100%;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
        
                            img {
                                display: block;
                                height: calc(100% - 0.8rem);
                                margin: 0.4rem 0;

                                border-radius: 0.4rem;
                            }
        
                            span {
                                margin-left: 0.4rem;
        
                                font-family: 'Roboto';
                                font-size: 1rem;
                                font-weight: 400;
                                color: $text-black;
                            }
                        }
                    }

                    .select__multi-value__remove {
                        color: $form-border;

                        &:hover {
                            color: $form-select-arrow-hover;
                            background: none;
                        }
                    }
                }
            }

            .PhoneInput {
                flex-grow: 2;
                margin-left: 0.8rem;
    
                input {
                    flex-grow: 2;
                    width: 100%;
                    padding: 0.4rem;

                    font-family: 'Roboto';
                    font-size: 1.2rem;
                    border: none;
                    background: none;
                }
            }

            .field-addon {
                margin-left: 0.4rem;
                font-family: 'Roboto';
                font-size: 1.2rem;
                color: $form-border;
            }

            .stars-container {
                display: flex;
                align-items: center;
                height: 2.225rem;
                padding: 0.4rem;
            }
        }

        div.error {
            margin-top: 0.4rem;
        
            font-size: 1.2rem;
            font-family: 'Noto Sans';
            font-weight: 700;
            color: $form-error-color;
        }

        &.customer-message {
            .message {
                margin-top: 0.4rem;
                padding: 0 0.8rem;

                font-family: 'Roboto';
                font-size: 1.1rem;
                color: $text-black;
        
                p {
                    margin: 0 !important;
                    margin-bottom: 0.8rem !important;
                    
                    line-height: 1.5rem !important;
                    text-align: justify !important;
    
                    &:last-child { margin-bottom: 0 !important; }
                }
        
                ul, ol { margin: 0.8rem 0 !important; }
                h1, h2, h3, h4, h5, h6 { color: $text-black !important; }
                h1, h2, h3 { margin: 0.8rem 0 !important; }
                h4, h5, h6 { margin: 0.4rem 0 !important; }
            }
        }
    }
    
    .buttons-container {
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 0 0.8rem;

        button {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0.8rem;
        
            font-family: 'Noto Sans';
            font-weight: 700;
            font-size: 1.2rem;
            line-height: 1.2rem;
            text-transform: uppercase;
            
            border: none;
            border-radius: 0.8rem;
            
            cursor: pointer;
            transition: all .4s;
            
            &.delete-button {
                margin-right: 1.6rem;
                color: $text-white;
                background: $form-delete-background;

                &:hover {
                    box-shadow: 0 0 0.8rem $form-delete-shadow; 
                }
            }
            
            &[type=submit] {
                color: $text-white;
                background: $form-save-background;

                &:hover {
                    box-shadow: 0 0 0.8rem $form-save-shadow;  
                }

                &:disabled {
                    background: $form-save-disabled-background;
                    box-shadow: none;
                    cursor: not-allowed;
                }
            }
            
            
            .material-icons {
                display: block;
                color: $text-white;
            }

            span:not(.material-icons) { margin-left: 0.4rem; }
        }
    }
}