@import '../../../../styles/colors';
@import '../../../../styles/dims';
@import '../../../../styles/forms';

div.CreateImage {
    width: 100%;
    
    .create-form {
        @extend .default-form
    }
}