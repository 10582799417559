@import '../../../styles/colors';
@import '../../../styles/dims';
@import '../../../styles/content';
@import '../../../styles/breakpoints';

div.Page {
    width: 100%;
    height: 100%;

    .page-content, .sections-content {
        @extend .default-content;

        opacity: 1;
        transform: translateY(0);

        transition: opacity .4s ease, transform .4s ease;

        @for $i from 1 through 10 {
            &:nth-child(#{$i}) {
                transition-delay: #{($i - 1) * .2}s;
            }
        }

        .page-info, .sections-list {
            @extend .default-list;

            .info {
                display: flex;
                justify-content: stretch;
                align-items: flex-start;
                padding: 0.8rem;
                margin-right: -0.8rem;
                margin-bottom: -0.8rem;

                @include media-max("phone") { 
                    flex-direction: column; 
                }
                
                .element {
                    margin-bottom: 0.8rem;
                    margin-right: 0.8rem;
                    
                    &.base-info { flex-grow: 2; }
                    
                    > div {
                        display: flex;
                        align-items: center;

                        .material-icons {
                            display: block;
                            color: $text-black;
                        }

                        span:not(.material-icons) {
                            margin-left: 0.4rem;
                            font-family: 'Roboto';
                            font-size: 1.1rem;
                            line-height: 1.2rem;
                            font-weight: 400;
                            color: $text-black;
                        }

                        .desc {
                            flex-grow: 2;

                            margin-left: 0.4rem;
                            margin-top: 0.4rem;

                            font-family: 'Roboto';
                            font-size: 1.1rem;
                            line-height: 1.5rem;
                            font-weight: 400;
                            color: $text-black;
                            
                            p {
                                width: auto;
                                margin: 0;
                                margin-bottom: 0.8rem;
                                
                                line-height: 1.5rem;
                                text-align: justify;
                            }

                            ul, ol { margin: 0.8rem 0 !important; }
                            h1, h2, h3, h4, h5, h6 { color: $text-black !important; }
                            h1, h2, h3 { margin: 0.8rem 0 !important; }
                            h4, h5, h6 { margin: 0.4rem 0 !important; }
                        }

                        img {
                            display: block;
                            height: 18rem;
                            margin-left: 0.4rem;

                            border-radius: 0.8rem;
                        }

                        &.desc-container, &.image-container {
                            align-items: flex-start;
                        }
                    }
                }
            }
        }
    }

    &.hidden {
        .page-content, .sections-content {
            opacity: 0;
            transform: translateY(8rem);
            transition: none;
        }
    }
}